import { Autocomplete, AutocompleteTextFieldProps } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { useMemo, VFC } from 'react';

import { FormFieldLayout } from '../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../design-system/body-sections/form/FormLayout';
import { AccountType } from '../../../../../types/domain/account/constants';
import {
  traitOptionsBuySide,
  traitOptionsSellSide,
} from '../../../../../types/domain/trait/constants';
import useTraitAccess from '../../../../shared/hooks/useTraitAccess';
import { ProductTraitsFormValues } from './ProductTraitsSection.model';

export type Props = {
  accountType: Readonly<AccountType | null>;
};

export const ProductTraitsSectionEdit: VFC<Props> = ({ accountType }) => {
  const formik = useFormikContext<ProductTraitsFormValues>();
  const { shouldShowTrait } = useTraitAccess();

  const traitOptions = useMemo(() => {
    return accountType === AccountType.BUY_SIDE
      ? traitOptionsBuySide.filter(option => shouldShowTrait(option.value, accountType))
      : traitOptionsSellSide.filter(option => shouldShowTrait(option.value, accountType));
  }, [accountType, shouldShowTrait]);

  const traitCodesTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      name: 'traitCodes',
      label: 'Existing Traits',
      error: Boolean(formik.errors.traitCodes),
      helperText: formik.errors.traitCodes,
      placeholder: 'Assign Traits...',
    }),
    [formik.errors.traitCodes]
  );

  return (
    <FormLayout>
      <FormFieldLayout>
        <Autocomplete
          multiple
          limitTags={2}
          options={traitOptions.map(option => option.value)}
          getOptionLabel={optionValue =>
            traitOptions.find(o => o.value === optionValue)?.label ?? ''
          }
          value={formik.values.traitCodes}
          onChange={(_e, value) => formik.setFieldValue('traitCodes', value)}
          TextFieldProps={traitCodesTextFieldProps}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
