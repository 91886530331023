import { AccountType } from '@cmg/auth';
import { useMemo } from 'react';

import { useFeatureToggles } from '../../../common/config/context/useFeatureToggles';
import { Trait } from '../../../types/domain/trait/constants';

const TraitsToToggle: Record<Trait | string, { featureFlag: string; accountTypes: AccountType[] }> =
  {
    [Trait.CERTIFICATE_LIBRARY]: {
      featureFlag: 'isCertificateLibraryOn',
      accountTypes: [AccountType.SELL_SIDE],
    },
    [Trait.CERTIFICATE_REVIEW]: {
      featureFlag: 'isCertificateReviewOn',
      accountTypes: [AccountType.SELL_SIDE],
    },
    [Trait.DATALAB_CALENDAR]: {
      featureFlag: 'isDatalabCalendarTraitAndRoleEnabled',
      accountTypes: [AccountType.SELL_SIDE, AccountType.BUY_SIDE],
    },
  };

const shouldShowTraitFn =
  (featureToggles: ReturnType<typeof useFeatureToggles>) =>
  (trait: Trait | string, accountType?: AccountType | null) => {
    const traitToToggle = TraitsToToggle[trait];

    if (!traitToToggle) {
      return true;
    }

    return accountType
      ? !!featureToggles[traitToToggle.featureFlag] &&
          traitToToggle.accountTypes.includes(accountType)
      : !!featureToggles[traitToToggle.featureFlag];
  };

const useTraitAccess = () => {
  const fetureToggles = useFeatureToggles();
  const shouldShowTrait = useMemo(() => shouldShowTraitFn(fetureToggles), [fetureToggles]);

  return { shouldShowTrait };
};

export default useTraitAccess;
